export const privacy = `<p class="t1">
주식회사 탑스맨(이하 ‘회사’)는 정보통신망 이용촉진 및 정보보호 등에
관한 법률, 개인정보 보호법, 통신비밀보호법, 전기통신사업법 등
정보통신서비스제공자가 준수하여야 할 관련 법규상의 개인정보보호
규정을 준수하며, 관련 법령에 따른 이용자의 권익 보호에 최선을 다하고
있습니다. <br />
회사는 정보통신망 이용촉진 및 정보보호 등에 관한 법률 제27조의2에
따라 정보주체의 개인정보를 보호하고 개인정보 관련 문제를 신속하고
원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보
처리방침을 운영합니다. <br /><br />
</p>
<strong>1. 개인정보의 수집 및 이용목적</strong> <br /><br />
<p>
회원의 취업과 인재채용 활동을 매개하는 온라인 리크루팅 사이트인
호구153.co.kr(이하 "사이트")은 효과적인 취업지원 · 인재채용 ·
경력개발에 적합한 서비스를 제공하기 위하여 개인정보를 수집하고
있으며 수집된 정보를 아래와 같이 이용하고 있습니다. 이용자가 제공한
모든 정보는 하기 목적에 필요한 용도 이외로는 사용되지 않으며 이용
목적이 변경될 시에는 사전 동의를 구할 것입니다. 또한, 회원이
"회사"가 요구하지 않은 개인정보를 사이트에 업로드한 경우, "회사"는
해당 정보를 이용하지 않으며, 이에 대한 관리책임 또한 없습니다.
</p>
<br /><br />

1) 회원관리<br />
회원제 서비스 이용에 따른 본인확인, 본인의 의사확인, 고객문의에 대한
응답, 새로운 정보의 소개 및 고지사항 전달<br /><br />

2) 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산<br />
본인인증, 채용정보 매칭 및 컨텐츠 제공을 위한 개인식별, 회원간의 상호
연락, 구매 및 요금 결제, 물품 및 증빙발송, 부정 이용방지와 비인가
사용방지<br /><br />

3) 서비스 개발 및 마케팅ㆍ광고에의 활용<br />
맞춤 서비스 제공, 서비스 안내 및 이용권유, 서비스 개선 및 신규 서비스
개발을 위한 통계 및 접속빈도 파악, 통계학적 특성에 따른 광고 게재,
이벤트 정보 및 참여기회 제공<br /><br />

4) 고용 및 취업동향 파악을 위한 통계학적 분석<br /><br />
<strong>2. 수집하는 개인정보 항목 및 수집방법</strong><br /><br />

"사이트"에서는 별도의 회원가입 절차 없이 특정 유료 서비스를 제외한
대부분의 컨텐츠에 자유롭게 접근할 수 있으며, 회원제 서비스를
이용하시려면 필수항목을 입력하여야 하며 선택항목을 입력하지 않더라도
서비스 이용에 제한을 두지 않습니다. <br /><br />

가. 수집하는 개인정보의 항목 <br /><br />
1) 회원가입 시 수집하는 항목 <br />
① 개인 회원<br />
이름, 성별, 생년월일, 아이디, 비밀번호, 휴대폰번호, e-메일<br />
② 소셜 회원<br />
네이버,카카오 등 외부 서비스와의 연동을 통해 이용자가 설정한 계정 정보<br />
③ 기업/서치펌 회원<br />
가입자명, 본인인증정보 또는 중복가입확인정보(DI), 아이디, 비밀번호,
전화번호, e-메일<br /><br />
2) 이력서 등록 시 수집하는 항목<br />
이름, 연락처(전화번호/휴대폰/e-메일), 학력사항, 경력사항, 생년월일,
성별, 사진, 홈페이지, 우편번호, 주소, 자기소개서,
취업우대사항(보훈대상, 취업보호대상, 장애여부, 병역사항,
고용지원금대상)<br /><br />
3) 채용정보 등록 시 수집하는 항목<br />
담당자의 정보는 채용 이외의 용도로 이용할 수 없으며, 신청하신 서비스의
원활한 이용과 정보도용 및 허위정보 게재로 인한 피해를 방지하기 위해
채용담당자의 개인정보를 확인하고 있습니다.<br />
채용공고 등록 시: 담당자 이름, 연락처(전화번호/휴대폰번호), e-메일<br /><br />
4) 유료서비스 이용 시 수집하는 항목<br />
서비스의 원활한 이용과 정보도용 및 허위정보 게재로 인한 피해를 방지하기 위해 채용담당자의 개인정보를 확인하고 있습니다.<br />
채용상품 신청 및 문의 시: 회사명, 담당자 이름, 전화번호, e-메일<br />
인재서칭(이력서열람) 신청 시: 담당자 이름, 중복가입확인정보(DI), 소속부서, 연락처(전화번호/휴대폰번호), e-메일<br /><br />
5) 추천정보 등록 시 수집하는 항목<br />
추천 링크 생성 과정에서 개인정보가 수집될 수 있으나, 이는 개인을 식별할 수 없는 형태입니다.<br />
추천인이 작성한 본인소개, 문자서비스 제공 시 이름/연락처, 추천인이 작성한 추천사, 명함{추천인 이름, 연락처(전화번호/휴대폰번호), 직장 e-메일, 직장명, 직장 주소, 회사 전화번호}<br /><br />
6) 모바일 서비스 이용 시 수집되는 항목<br />
hogoo153.com의 PUSH 서비스 이용 시 : 발신자 전화번호<br /><br />
7) 서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.<br />
IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록, 광고 ID
모바일 서비스의 특성상 단말기 모델 정보가 수집될 수 있으나, 이는 개인을 식별할 수 없는 형태입니다.<br /><br />

나. 개인정보 수집방법<br />
홈페이지, 서비스 이용, 이벤트 응모, 팩스, 우편, 전화, 고객센터
문의하기<br /><br />

<strong>3. 개인정보의 제공 및 처리위탁</strong><br /><br />
"회사"는 개인정보를 1. 개인정보의 수집 및 이용목적에서 고지한 범위
내에서 사용하며, 이용자의 사전 동의 없이 동 범위를 초과하여 이용하지
않습니다.<br /><br />

가. 처리위탁<br />
"회사"는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며,
관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록
필요한 사항을 규정하고 있습니다.<br />
변동사항 발생 시 공지사항 또는 개인정보처리방침을 통해 고지하도록
하겠습니다.<br /><br />

나. 다음의 경우에는 합당한 절차를 통한 이용자의 동의를 얻어 개인정보를
제공 또는 이용할 수 있습니다. <br /><br />
1) 제휴관계: 본 개인정보처리방침 시행일 현재에는 개인회원의 개인정보를
제공하는 제휴관계가 없으며, 제휴를 통한 변동사항 발생 시 사전공지
합니다. 이 경우 개별적인 동의를 구하는 절차를 거치며, 동의가 없는
경우에는 제공하지 않습니다. <br /><br />
2) 매각, 인수합병: 서비스 제공자의 권리, 의무가 승계 또는 이전되는
경우 이를 반드시 사전에 고지하며 이용자의 개인정보에 대한 동의철회의
선택권을 부여합니다<br /><br />
3) 다만, 아래의 경우에는 예외로 합니다.<br />
관계법령에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라
수사기관의 요구가 있는 경우<br /><br />

<strong> 4. 개인정보의 보유 및 이용기간</strong><br /><br />
"사이트"는 회원가입일로부터 서비스를 제공하는 기간 동안에 한하여
이용자의 개인정보를 보유 및 이용하게 됩니다. 회원탈퇴를 요청하거나
개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 수집 및
이용목적이 달성되거나 이용기간이 종료한 경우 개인정보를 지체 없이
파기합니다.<br />
단, 다음의 경우에 대해서는 각각 명시한 이유와 기간 동안 보존합니다.<br /><br />

1) 상법 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 법령에서
규정한 보존기간 동안 거래내역과 최소한의 기본정보를 보유합니다.<br />
이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용합니다.<br />
① 계약 또는 청약철회 등에 관한 기록: 5년<br />
② 대금결제 및 재화 등의 공급에 관한 기록: 5년<br />
③ 소비자의 불만 또는 분쟁처리에 관한 기록: 3년<br />
④ 부정이용 등에 관한 기록: 5년<br />
⑤ 웹사이트 방문기록(로그인 기록, 접속기록): 3개월<br /><br />
2) 보유기간을 미리 공지하고 그 보유기간이 경과하지 아니한 경우와
개별적으로 동의를 받은 경우에는 약정한 기간 동안 보유합니다.<br /><br />
3) 개인정보보호를 위하여 이용자가 선택한 개인정보 보유기간(1년, 3년,
회원탈퇴시) 동안 "사이트"를 이용하지 않은 경우, "아이디"를
"휴면계정"로 분리하여 해당 계정의 이용을 중지할 수 있습니다. 이 경우
"회사"는 "휴면계정 처리 예정일"로부터 30일 이전에 해당사실을 전자메일,
서면, SMS 중 하나의 방법으로 사전통지하며 이용자가 직접 본인확인을
거쳐, 다시 "사이트" 이용 의사표시를 한 경우에는 "사이트" 이용이
가능합니다.<br /><br />
4) 아이디의 경우 중복가입확인 등의 목적으로 영구 보유합니다.<br /><br />
<strong> 5. 개인정보 파기절차 및 파기방법</strong><br /><br />
이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면
지체 없이 파기합니다. "회사"의 개인정보 파기절차 및 방법은 다음과
같습니다.<br /><br />

1) 파기절차<br />
이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로
옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련법령에 의해
정보보호 사유에 따라 일정 기간 저장된 후 파기됩니다.<br />
별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른목적으로
이용되지 않습니다.<br />
이용자가 선택한 개인정보 보유기간(회원탈퇴시 포함)이 지난 개인정보는 별도로 분리 보관 또는 삭제하고 있으며, 분리 보관된 개인정보는 5년간 보관 후 지체없이 파기합니다.<br /><br />
2) 파기방법<br />
종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통해 파기합니다.
전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.<br /><br />
<strong>
6. 개인정보 자동 수집 장치의 설치, 운영 및 거부에 관한 사항</strong
><br /><br />
1) 쿠키란<br />
웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 작은
텍스트 파일로 이용자의 하드디스크에 저장됩니다.<br /><br />

2) 쿠키의 사용 목적<br />
"사이트"가 쿠키를 통해 수집하는 정보는 '2. 수집하는 개인정보 항목 및
수집방법'과 같으며 '1. 개인정보의 수집 및 이용목적'외의 용도로는
이용되지 않습니다.<br /><br />

3) 쿠키 설치, 운영 및 거부<br />
이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 웹브라우저에서
옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다
확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.<br />
쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의 경우)은 다음과
같습니다. 예)웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보<br />
단, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 일부 서비스 이용에
어려움이 있을 수 있습니다.<br /><br />
<strong> 7. 개인정보의 기술적, 관리적 보호대책</strong><br /><br />
1) 개인정보 암호화<br />
이용자의 개인정보는 비밀번호에 의해 보호되며, 파일 및 각종 데이터는
암호화하거나 파일 잠금 기능을 통해 별도의 보안기능을 통해 보호하고
있습니다.<br /><br />

2) 해킹 등에 대비한 대책<br />
해킹이나 컴퓨터 바이러스 등에 의한 피해를 방지하기 위하여 백신
프로그램을 주기적으로 업데이트하며 새로운 바이러스가 출현할 경우
백신이 나오는 즉시 적용하여 개인정보가 훼손되지 않도록 방지하고
있습니다.<br />
외부 침입에 대비하여 접근이 통제된 구역에 시스템을 설치하고, 침입탐지
시스템 및 취약점 분석 시스템을 설치하여 24시간 감시하고 있습니다.<br /><br />

3) 개인정보 처리 직원의 교육<br />
개인정보관련 처리 직원은 최소한의 인원으로 구성되며, 새로운 보안기술
습득 및 개인정보보호 의무에 관해 정기적인 교육을 실시하며 내부 감사
절차를 통해 보안이 유지되도록 시행하고 있습니다.<br /><br />

4) 개인 아이디와 비밀번호 관리<br />
"회사"는 이용자의 개인정보를 보호하기 위하여 최선의 노력을 다하고
있습니다. 단, 이용자의 개인적인 부주의로 ID, 비밀번호등 개인정보가
유출되어 발생한 문제와 기본적인 인터넷의 위험성 때문에 일어나는 일들에
대해 책임을 지지 않습니다.<br /><br />

<strong> 8. 링크</strong><br /><br />
"사이트"는 다양한 배너와 링크를 포함하고 있습니다. 많은 경우 타
사이트의 페이지와 연결되어 있으며 이는 광고주와의 계약관계에 의하거나
제공받은 컨텐츠의 출처를 밝히기 위한 조치입니다.<br /><br />

"사이트"가 포함하고 있는 링크를 클릭하여 타 사이트의 페이지로 옮겨갈 경우 해당 사이트의 개인정보처리방침은 hogoo153과 무관하므로 새로 방문한 사이트의 정책을 검토해 보시기 바랍니다.<br /><br />

<strong>9. 아동의 개인정보 보호</strong> <br /><br />
"회사"는 인터넷 리크루팅의 특성으로 볼 때 이력서를 등록하여 구직활동을
할 수 없다고 판단하여 만15세 미만 아동의 회원가입을 허용하지
않습니다.<br /><br />

<strong>10. 비회원의 개인정보 보호</strong> <br /><br />
"사이트"에서는 회원 가입을 하지 않아도 공개를 희망하지 않는 개인정보와
이력서 주요 정보를 제외한 대부분의 컨텐츠를 열람할 수 있습니다.<br /><br />

<strong>11. 이용자의 권리와 그 행사방법</strong> <br /><br />
1) 이용자는 ‘회원정보관리’를 통하여 언제든지 등록되어 있는 자신의
정보를 열람, 공개 및 비공개, 수정할 수 있습니다.<br /><br />
2) 이용자는 언제든지 ‘회원탈퇴’ 등을 통해 개인정보의 수집 및 이용
동의를 철회할 수 있습니다.<br /><br />
3) 이용자는 서면, 전화 또는 이메일 등의 방법으로 회사에 제1항, 제2항의
사항을 요청할 수 있습니다.<br /><br />
4) 회사는 이용자의 요청에 즉시 답변이 불가능하거나 거절의 의사표시를
해야 하는 경우 이용자의 요청을 받은 날로부터 10일 이내에 답변의 연기
또는 거절의 정당한 사유 등을 이용자에게 통지합니다.<br /><br />
5) 이용자는 회사의 조치에 이의가 있는 경우 개인정보 열람 등 요구 결정
이의신청서 서식으로 이의신청할 수 있습니다.<br /><br />
<strong>12. 개인정보 보호책임자 및 민원서비스</strong> <br /><br />
"회사"는 이용자의 개인정보를 보호하고 개인정보와 관련한 고충처리를
위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.<br /><br />
<table>
<thead>
  <tr>
    <th>개인정보 보호책임자</th>
  </tr>
</thead>
<tbody>
  <tr>
    <td>
      이름: 방효은 <br />
      소속/직위: 기술본부/전무<br />
      e-메일: baang1@hogoo153.com<br />
      전화: 02)863-9153
    </td>
  </tr>
</tbody>
</table>
<br /><br />
기타 개인정보에 관한 상담이 필요한 경우에는 아래 기관에 문의하실 수
있습니다.<br /><br />

<table>
<tbody>
  <tr>
    <td>개인정보침해신고센터</td>
    <td>http://privacy.kisa.or.kr/ 국번없이 118</td>
  </tr>
  <tr>
    <td>대검찰청 사이버수사과</td>
    <td>http://www.spo.go.kr/ 국번없이 1301</td>
  </tr>
  <tr>
    <td>경찰청 사이버안전지킴이</td>
    <td>http://www.police.go.kr/ 국번없이 182</td>
  </tr>
</tbody>
</table>
<br /><br />
<strong> 13. 부칙</strong><br /><br />
현 개인정보처리방침은 법령, 정책 또는 보안기술의 변경 등에 따라 내용
추가, 삭제 및 수정이 있을 시에는 시행일의 7일 전부터 홈페이지의
공지사항을 통하여 고지할 것입니다.<br /><br />
개인정보처리방침 버전번호: 1.0<br />
시행일자: 2021년 10월 25일`

export const enterprise = `<strong>제 1 조 (목적)</strong><br /><br />
본 약관은 ㈜ 탑스맨(이하 "회사")가 운영하는 "서비스"를 이용함에 있어
"회사"와 회원간의 이용 조건 및 제반 절차, 권리, 의무 및 책임사항, 기타
필요한 사항을 규정함을 목적으로 한다.<br /><br />
<strong>제 2 조 (용어의 정의)</strong><br /><br />
이 약관에서 사용하는 용어의 정의는 아래와 같다<br /><br />

① "사이트"라 함은 회사가 서비스를 "회원"에게 제공하기 위하여 컴퓨터 등
정보통신설비를 이용하여 설정한 가상의 영업장 또는 회사가 운영하는
웹사이트, 모바일웹, 앱 등의 서비스를 제공하는 모든 매체를 통칭하며,
통합된 하나의 회원 계정(아이디 및 비밀번호)을 이용하여 서비스를 제공받을
수 있는 아래의 사이트를 말한다.<br /><br />
- www.hogoo153.com <br />
- www.hogoo153.co.kr<br />
- www.hogoo153.net<br />
- www.hogoo153.kr<br />
- www.호구153.com<br /><br />
② "서비스"라 함은 회사가 운영하는 사이트를 통해 기업(단체, 사업자,
교육기관)이 직원채용을 목적으로 등록하는 자료를 DB화하여 각각의 목적에
맞게 분류 가공, 집계하여 정보를 제공하는 서비스와 사이트에서 제공하는
모든 부대 서비스를 말한다.<br />
③ "회원"이라 함은 기업의 필요한 인재를 고용할 것을 목적으로 약관에
동의하고 아이디를 부여받아 회사의 서비스를 이용하는 자를 말하며,
아웃소싱(도급), 인재파견, 채용대행 기업을 포함한다. 또한, 회원은 각
사업자 별로 아이디를 부여 받아 회사의 서비스를 이용하여야 한다.<br />
④ "아이디"라 함은 회원가입시 회원의 식별과 회원의 서비스 이용을 위하여
회원이 선정하고 "회사"가 부여하는 문자와 숫자의 조합을 말한다.<br />
⑤ "비밀번호"라 함은 위 제4항에 따라 회원이 회원가입시 아이디를
설정하면서 아이디를 부여받은 자와 동일인임을 확인하고 "회원"의 권익을
보호하기 위하여 "회원"이 선정한 문자와 숫자의 조합을 말한다.<br />
⑥ "비회원"이라 함은 회원가입절차를 거치지 않고 "회사"가 제공하는
서비스를 이용하거나 하려는 자를 말한다.<br /><br />

<strong>제 3 조 (약관의 명시와 개정)</strong><br /><br />
① "회사"는 이 약관의 내용과 상호, 영업소 소재지, 대표자의 성명,
사업자등록번호, 연락처 등을 이용자가 알 수 있도록 초기 화면에 게시하거나
기타의 방법으로 이용자에게 공지해야 한다.<br />
② "회사"는 약관의 규제에 관한 법률, 전기통신기본법, 전기통신사업법,
정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관련법을 위반하지 않는
범위에서 이 약관을 개정할 수 있다.<br />
③ "회사"가 약관을 개정할 경우 적용일자 및 개정사유를 명시하여 현행약관과
함께 그 개정약관의 적용일자 7일 전부터 적용일자 전일까지 공지한다. 단
"회원"의 권리, 의무에 중대한 영향을 주는 변경의 경우에는 적용일자 30일
전부터 공지하도록 한다.<br />
④ "회원"은 변경된 약관에 대해 거부할 권리가 있다. "회원"은 변경된 약관이
공지된 지 15일 이내에 거부의사를 표명할 수 있다. "회원"이 거부하는 경우
본 서비스 제공자인 "회사"는 15일의 기간을 정하여 "회원"에게 사전 통지 후
당해 "회원"과의 계약을 해지할 수 있다. 만약, "회원"이 거부의사를
표시하지 않거나, 전항에 따라 시행일 이후에 "서비스"를 이용하는 경우에는
동의한 것으로 간주한다.<br /><br />

<strong>제 4 조 (약관의 해석)</strong><br /><br />
① 이 약관에서 규정하지 않은 사항에 관해서는 약관의 규제에 관한 법률,
전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에
관한 법률 등의 관계법령에 따른다.<br />
② 각 사이트 및 서비스 이용약관이 있는 경우에는 서비스 이용약관이
우선한다.<br />
③ "회원"이 "회사"와 개별 계약을 체결하여 서비스를 이용할 경우에는 개별
계약이 우선한다.<br /><br />

<strong>제 5 조 (이용계약의 성립)</strong><br /><br />
① "회사"의 서비스 이용계약(이하 "이용계약")은 서비스를 이용하고자 하는
자가 본 약관과 개인정보처리방침을 읽고 "동의" 또는 "확인" 버튼을 누른
경우 본 약관에 동의한 것으로 간주한다.<br />
② 서비스 이용계약 체결에 필요한 경우 "회사"는 전문기관을 통한 실명확인
및 본인인증을 요청할 수 있다. "회원"은 본인인증에 필요한 이름, 생년월일,
연락처 등을 제공하여야 한다, 또한 서비스 이용을 위해 회사명과
사업자등록번호를 제공하여야 하며 "회사"는 기업인증을 위해 사업자정보를
확인할 수 있는 증명서(사업자등록증)를 별도로 요구할 수 있다. 회사명과
사업자등록번호를 허위로 등록한 경우, 휴·폐업 사업자등록번호로 등록한
경우에 "회원"은 일체의 권리를 주장할 수 없다.<br />
③ 회사는 회원 약관에 동의한 "회원"에 웹상의 안내 및 전자메일로
통지함으로써 서비스 이용계약의 성립을 확인한다.<br />
④ 사업자등록번호가 없는 기관이나 단체는 "회사"가 정한 별도의 절차에 따라
이용계약을 할 수 있다.<br /><br />

<strong>제 6 조 (이용신청의 승낙과 제한)</strong><br /><br />
① "회사"는 전조의 규정에 의한 이용신청 고객에 대하여 업무수행상 또는
기술상 지장이 없는 경우에는 원칙적으로 접수순서에 따라 서비스 이용을
승낙한다.<br />
② "회사"는 아래 사항에 해당하는 경우에 대해서는 서비스 이용신청을
승낙하지 아니한다.<br />
1. 실제 회사명과 사업자등록번호가 아니거나 타사의 정보를 이용하여 신청한
경우<br />
2. 이용계약 신청서의 내용을 허위로 기재하거나 "회사"가 제시하는 내용을
기재하지 않은 경우<br />
3. 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반
사항을 위반하며 신청하는 경우<br />
③ "회사"는 아래 사항에 해당하는 경우에는 그 신청에 대하여 승낙제한
사유가 해소될 때까지 승낙을 유보할 수 있다.<br />
1. "회사"가 설비의 여유가 없는 경우<br />
2. "회사"의 기술상 지장이 있는 경우<br />
3. 기타 "회사"의 귀책사유로 이용승낙이 곤란한 경우<br /><br />

<strong>제 7 조 (서비스의 내용)</strong><br /><br />
① "회사"는 제2조 2항의 서비스를 제공할 수 있으며 그 내용은 다음 각 호와
같다.<br />
1. 채용정보 등록서비스<br />
2. 온라인 채용관리 서비스<br />
3. 구인/구직과 관련된 제반 서비스<br />
4. 이력서 열람 서비스<br />
5. 이용자간의 교류와 소통과 관련된 서비스<br />
6. 자료 거래에 관련된 서비스<br />
7. 구인자, 구직자 상호 평가 및 이용후기 서비스<br />
8. 일자리 매칭서비스<br />
9. 위치기반 근태관리 서비스<br />
10. 위치정보에 관한 서비스 <br />
11. 기타 "회사"가 추가 개발하거나 제휴계약 등을 통해 "회원"에게 제공하는
일체의 서비스<br />
② "회사"는 필요한 경우 서비스의 내용을 추가 또는 변경할 수 있다. 단, 이
경우 "회사"는 추가 또는 변경내용을 "회원"에게 공지해야 한다.<br /><br />

<strong
  >제 8 조 ("회원"정보, 채용공고 등록, 채용의뢰, 이력서검색)</strong
><br /><br />
① "회원"은 채용공고 등록 시 기업정보를 정확히 기재하여야 한다.<br />
② "회원"은 아웃소싱(도급), 인재파견 또는 채용대행 공고를 등록하는 경우
구직을 희망하는 개인회원이 자사의 직접 고용 목적으로 등록한 채용공고와
오인·혼동 되지 않도록 하여야 한다.<br />
③ "회원"은 직업안정법 제34조 및 동법 시행령 제 34조 거짓구인광고의
범위에 위반되지 않도록 채용공고를 작성하여 등록해야 하며 그 내용은
다음과 같다.<br />
1. 구인을 가장하여 물품판매, 수강생 모집, 직업소개, 부업알선, 자금모집
등을 행하는 광고<br />
2. 거짓 구인을 목적으로 구인자의 신원(업체명 또는 성명)을 표시하지
아니하는 광고<br />
3. 구인자가 제시한 직종, 고용형태, 근로조건 등이 응모할 때의 그것과
현저히 다른 광고<br />
4. 기타 광고의 중요내용이 사실과 다른 광고<br />
④ "회원"은 관련 법률에 의거하여 근로자의 모집 및 채용에 있어서 남녀,
연령을 차별하여서는 아니되며 15세 미만 채용 모집을 할 수 없다. 단, 제6조
제2항 제3호에 경우는 예외로 한다.<br />
⑤ 제 3항, 제 4항을 위반한 경우 이에 대한 책임은 전적으로 "회원"에게
있다.<br />
⑥ "회사"는 "회원"이 등록한 기업정보 및 채용공고를 "회사"가 정한 방법에
의해 노출할 수 있다.<br />
⑦ "회원" 중 아웃 소싱(도급), 인재파견, 채용대행 기업은 "회사"에
채용파트너관 서비스를 신청하여 자사의 HR상품을 홍보할 수 있으며, 일반
기업 "회원"은 채용파트너관에 업로드 된 HR상품 및 사업분야를 선택하여
상담의뢰를 신청할 수 있다. 단, 채용파트너관 서비스 이용 시 수집되는
개인정보는 본 서비스 이용 이외의 목적으로 사용할 수 없으며, 각 개인의
동의 없이 개인정보를 기업의 영업·마케팅의 용도로 사용하거나 제3자에게
제공 시 개인정보보호법, 정보통신망이용촉진 및 정보보호 등에 관한 법률에
의거하여 법적 책임을 질 수 있다.<br />
⑧ "회원"은 직원 채용의 목적으로 유·무료로 개인회원이 등록한 이력서를
검색할 수 있으며 "회원"은 "회사"가 제시하는 별도의 커뮤니케이션을 통하여
개인회원에게 연락을 취할 수 있다. 단, 이력서 연락처의 열람 및 연락의
목적은 직원 채용활동에 국한되어야 하며, 기업의 영업·마케팅·제3자 제공
시에는 개인정보보호법, 직업안정법, 정보통신망이용촉진 및 정보보호 등에
관한 법률에 의거하여 법적 책임을 질 수 있다.<br /><br />

<strong>제 9 조 (제휴를 통한 서비스)</strong><br /><br />
① "회사"는 제휴 관계를 체결한 여타 인터넷 웹사이트 및 채용박람회 또는
신문, 잡지 등의 오프라인 매체를 통해 사이트에 등록한 "회원"의 기업정보와
채용공고가 열람될 수 있도록 서비스를 제공할 수 있다.<br />
② "회사"는 제휴를 통해 타 사이트 및 매체에 등록될 수 있음을 고지해야
하며, 제휴 사이트 목록을 사이트내에서 상시 열람할 수 있도록 해야 한다.
단, 등록의 형태가 "회사"가 직접 구축하지 않고, 제휴사가 xml 또는 api
형태로 "회사"로부터 제공 받아 구축한 매체 목록은 본 약관외 별도의
제휴리스트에서 열람할 수 있도록 한다.<br />
③ "회사"는 제휴 사이트를 통해 "회원"이 등록한 기업정보와 채용정보를
제공한다.<br />
④ 본 조 제 3항 제휴를 통한 사이트의 변동사항 발생 시 공지사항을 통해
고지 후 진행 한다.<br /><br />

<strong>제 10 조 (서비스의 요금)</strong><br /><br />
① "회원" 가입과 채용공고 등록비용은 무료이다. 다만 개인회원 또는
사이트에 방문한 회원에게 “회원”의 정보를 보다 효과적으로 노출시키기 위한
유료옵션과 즉시등록 서비스, 유료 이력서 검색 및 기타 서비스를 이용하기
위한 별도의 서비스는 유료로 제공될 수 있다.<br />
② "회사"는 유료서비스를 제공할 경우 사이트에 요금에 대해서 공지를 하여야
한다.<br />
③ "회사"는 유료서비스 이용금액을 서비스의 종류 및 기간에 따라 "회사"가
예고 없이 변경할 수 있다. 다만, 변경 이전에 적용 또는 계약한 금액은
소급하여 적용하지 아니한다.<br />
④ “회원”은 개인회원의 업무 완료 건 당 다음 각 호의 기준에 따라
급여,수수료,유료서비스이용료, 부가가치세를 “회사”에 지급한다. 이때,
급여산정은 프로그램(앱)과 회사가 정한 공지채널(SNS) 등을 통해 사전에
공지하며, 변경 있을 시 즉시 공지합니다.<br />
1. 기본 급여(세전 금액 기준) : “회원”이 정한 조건으로 거래확정 시
산정<br />
2. 추가 급여 : 긴급상황, 지역, 거리, 날씨, 시간 등을 고려하여 산정.<br />
3. 수수료 : “개인회원” 에게 지급 할 급여의 10% <br />
4. 플랫폼 이용료<br />
5. 서비스이용료 : 화면 노출 등 각 종 기능 사용시 <br />
6. 부가가치세 : 급여+수수료+서비스 이용료의 10% <br />
⑤ “회사”는 프로모션에 의해 포인트를 지급할 수 있으며, 위 프로모션은
“회원”에 대한 전화, 문자메시지, 이메일, 프로그램(앱), 회사가 정한
공지채널(SNS) 등의 방법으로 실시간 공지될 수 있다.<br />
⑥ “회원”은 개인회원에게 업무 배정시 “회원”이 정한 해당 건으로
개인회원에게 지급할 급여 예상액을 제시한다.<br />
⑦ “회원” 개인회원과 거래 확정 된 거에 대하여 당일 “회사”에게 회사
명의계좌로 급여,수수료 등을 지급합니다. 단, “회원”이 보유중인 충전금이
있을시 “회사”는 해당금액을 공제한다.<br />
⑧ “회사”는 “회원”에게 급여,수수료,유료서비스 이용금액등 내역을
교부하거나 “회원” 요청 시 상시 열람할 수 있도록 협조한다.<br /><br />

<strong>제 11 조 (이용요금 오류의 조정)</strong><br /><br />
"회사"는 이용요금과 관련하여 오류가 있는 경우에 "회원"의 요청, 또는
"회사"의 사전 통지에 의하여 다음에 해당하는 조치를 취한다.<br />
1. 과다 납입한 요금에 대하여는 그 금액을 반환한다. 다만, "회원"이 동의할
경우 다음에 청구할 요금에서 해당 금액만큼을 감하여 청구한다.<br />
2. 요금을 반환받아야 할 "회원"이 요금체납이 있는 경우에는 반환해야 할
요금에서 이를 우선 공제하고 반환한다.<br />
3. "회사"는 과소청구액에 대해서는 다음에 합산 청구한다.<br /><br />

<strong>제 11 조 (서비스 요금의 환불)</strong><br /><br />
① “회사”는 다음 각 호에 해당하는 경우 이용요금을 환불한다. 단, 각
당사자의 귀책사유에 따라 환불 조건이 달라질 수 있다.<br />
1. 유료서비스 이용이 개시되지 않은 경우<br />
2. 네트워크 또는 시스템 장애로 서비스 이용이 불가능한 경우<br />
3. 유료서비스 신청 후 “회원”의 사정에 의해 서비스가 취소될 경우<br />
② “회사”가 본 약관 제20조에 따라 가입해지/서비스중지/자료삭제를 취한
경우, “회사”는 “회원”에게 이용요금을 환불하지 않으며, 별도로 “회원”에게
손해배상을 청구할 수 있다.<br />
③ 이용료를 환불받고자 하는 회원은 고객센터로 환불을 요청해야 한다.<br />
④ “회사”는 환불 요건에 부합하는 것으로 판단될 경우, 각 서비스 환불
안내에 따라 유료이용 계약 당시 상품의 정가 기준으로 서비스 제공된 기간에
해당하는 요금을 차감한 잔액을 환불한다.<br /><br />

<strong>제 12 조 (충전금 규정)</strong><br /><br />
① 충전금은 "회사"가 정한 결제수단을 이용하여 미리 입금해 둔 금액으로,
개인기업의 급여,수수료, "회사"의 유료서비스,부가가치세 결제 시 사용할 수
있다.<br />
② 서비스 운영정책에 따라 충전금을 구매할 수 있는 수단, 충전금으로 결제할
수 있는 유료서비스 등은 달라질 수 있으며, 이에 대한 사항은 서비스 화면에
게시하거나 공지한다.<br />
③ "회원"은 "회사"에 잔여 충전금의 환불을 요청할 수 있으며, "회사"는
"회원"에게 환불에 필요한 서류를 요청할 수 있다. 환불 시 환불수수료를
공제하고 환불할 수 있으며, 충전금 충전방법에 따라 금융비용이
환불수수료를 초과하여 발생할 경우 이는 "회원"의 부담으로 한다.<br />
④ 잔여 충전금 중 충전일로부터 5년이 초과할 때까지 이용하지 않은 금액은
상사소멸시효에 따라 소멸된다.<br />
⑤ 회원탈퇴 시 보유중인 충전금은 환불을 요청할 수 있으며, 이와 같은
절차없이 회원탈퇴한 경우 충전금은 전액 자동소멸되어 복구되지 않는다.<br /><br />

<strong>제 13 조 (서비스 이용시간)</strong><br /><br />
① "회사"는 특별한 사유가 없는 한 연중무휴, 1일 24시간 서비스를 제공한다.
다만, "회사"는 서비스의 종류나 성질에 따라 제공하는 서비스 중 일부에
대해서는 별도로 이용시간을 정할 수 있으며, 이 경우 "회사"는 그
이용시간을 사전에 "회원"에게 공지 또는 통지하여야 한다.<br />
② "회사"는 자료의 가공과 갱신을 위한 시스템 작업시간, 장애해결을 위한
보수작업 시간, 정기 PM작업, 시스템 교체작업, 회선 장애 등이 발생한 경우
일시적으로 서비스를 중단할 수 있으며 계획된 작업의 경우 공지란에 서비스
중단 시간과 작업 내용을 알려야 한다. 다만, "회사"가 사전에 통지할 수
없는 부득이한 사유가 있는 경우 사후에 통지할 수 있다.<br /><br />

<strong>제 14 조 (서비스 제공의 중지)</strong><br /><br />
① "회사"는 다음 각 호에 해당하는 경우 서비스의 제공을 중지할 수 있다.<br />
1. 설비의 보수 등 "회사"의 필요에 의해 사전에 "회원"들에게 통지한
경우<br />
2. 기간통신사업자가 전기통신서비스 제공을 중지하는 경우<br />
3. 기타 불가항력적인 사유에 의해 서비스 제공이 객관적으로 불가능한
경우<br />
② 전항의 경우, "회사"는 기간의 정함이 있는 유료서비스 이용자들에게는 그
이용기간을 연장하는 등의 방법으로 손실을 보상하여야 한다.<br />
③ "회원"인 개인사업자와 법인사업자의 휴·폐업 여부가 회사가 정한 별도의
절차에 따라 확인된 경우 유료 이력서 검색서비스(건수별 인재검색)의 경우
적립된 건수는 모두 소멸된다.<br /><br />

<strong>제 15 조 (정보의 제공 및 광고의 게재)</strong><br /><br />
① "회사"는 "회원"에게 서비스 이용에 필요가 있다고 인정되거나 서비스 개선
및 회원대상의 서비스 소개 등의 목적으로 하는 각종 정보에 대해서
전자우편이나 서신우편을 이용한 방법으로 제공할 수 있다.<br />
② "회사"는 제공하는 서비스와 관련되는 정보 또는 광고를 서비스 화면,
홈페이지, 전자우편 등에 게재할 수 있으며, 광고가 게재된 전자우편을
수신한 "회원"은 수신거절을 "회사"에게 할 수 있다.<br />
③ "회사"는 서비스상에 게재되어 있거나 본 서비스를 통한 광고주의
판촉활동에 "회원"이 참여하거나 교신 또는 거래를 함으로써 발생하는 모든
손실과 손해에 대해 책임을 지지 않는다.<br />
④ 본 서비스의 "회원"은 서비스 이용 시 노출되는 광고게재에 대해 동의 하는
것으로 간주한다.<br /><br />

<strong>제 16 조 (자료내용의 책임과 "회사"의 정보 수정 권한)</strong
><br /><br />
① 자료내용은 "회원"이 등록한 기업정보 및 채용공고와 사이트에 게시한
게시물을 말한다.<br />
② "회원"은 자료 내용 및 게시물을 사실에 근거하여 성실하게 작성해야 하며,
만일 자료의 내용이 사실이 아니거나 부정확하게 작성되어 발생하는 모든
책임은 "회원"에게 있다.<br />
③ 모든 자료내용의 관리와 작성은 "회원" 본인이 하는 것이 원칙이나 사정상
위탁 또는 대행관리를 하더라도 자료내용의 책임은 "회원"에게 있으며
"회원"은 주기적으로 자신의 자료를 확인하여 항상 정확하게 관리가 되도록
노력해야 한다.<br />
④ "회사"는 "회원"이 등록한 자료 내용에 오자, 탈자 또는 사회적 통념에
어긋나는 문구와 내용이 있을 경우 이를 언제든지 수정할 수 있다.<br />
⑤ "회원"이 등록한 자료로 인해 타인(또는 타법인)으로부터 허위사실 및
명예훼손 등으로 삭제 요청이 접수된 경우 "회사"는 "회원"에게 사전 통지
없이 본 자료를 삭제할 수 있으며 삭제 후 메일 등의 방법으로 통지할 수
있다.<br /><br />

<strong>제 17 조 (자료 내용의 활용 및 취급)</strong><br /><br />
① "회원"이 입력한 정보는 취업 및 관련 동향의 통계 자료로 활용될 수
있으며 그 자료는 매체를 통한 언론 배포 또는 제휴사에게 제공될 수
있다.<br />
② "사이트"에서 정당한 절차를 거쳐 열람한 개인회원의 이력서 정보는 해당
"회원"의 인사자료이며 이에 대한 관리 권한은 해당 "회원"의 정책에
의한다.<br />
③ "회사"는 "사이트"의 온라인 입사지원 시스템을 통해 지원한 개인회원의
이력서 열람 여부 및 "회원"이 제공한 채용정보에 입사지원한 구직자들의
각종 통계데이터를 개인회원에게 제공할 수 있다.<br /><br />

<strong>제 18 조 ("회사"의 의무)</strong><br /><br />
① "회사"는 본 약관에서 정한 바에 따라 계속적, 안정적으로 서비스를 제공할
수 있도록 최선의 노력을 다해야 한다.<br />
② "회사"는 서비스와 관련한 "회원"의 불만사항이 접수되는 경우 이를 즉시
처리하여야 하며, 즉시 처리가 곤란한 경우에는 그 사유와 처리일정을 서비스
화면 또는 기타 방법을 통해 동 "회원"에게 통지하여야 한다.<br />
③ "회사"는 유료 결제와 관련한 결제 사항 정보를 1년 이상 보존한다. 다만
회원 자격이 없는 회원은 예외로 한다.<br />
④ 천재지변 등 예측하지 못한 일이 발생하거나 시스템의 장애가 발생하여
서비스가 중단될 경우 이에 대한 손해에 대해서는 "회사"가 책임을 지지
않는다. 다만 자료의 복구나 정상적인 서비스 지원이 되도록 최선을 다할
의무를 진다.<br />
⑤ "회원"의 자료를 본 서비스 이외의 목적으로 제3자에게 제공하거나
열람시킬 경우 반드시 "회원"의 동의를 얻어야 한다.<br />
⑥ "회원"이 "사이트"에 등록한 기업정보, 채용공고 내용은 사이트를 방문하는
일반 대중에게 공개함을 원칙으로 한다. 단, 회사가 운영하는 공식
SNS채널(페이스북, 인스타그램, 카카오스토리, 트위터, 블로그 등)에 한해
채용공고 내용을 업로드하여 일반 대중에게 공개할 수 있다.<br /><br />

<strong>제 19 조 ("회원"의 의무)</strong><br /><br />
① "회원"은 관계법령과 본 약관의 규정 및 기타 "회사"가 통지하는 사항을
준수하여야 하며, 기타 "회사"의 업무에 방해되는 행위를 해서는 안 된다.<br />
② "회원"이 신청한 유료서비스는 등록 또는 신청과 동시에 "회사"와 채권,
채무 관계가 발생하며, "회원"은 이에 대한 요금을 지정한 기일 내에
납부하여야 한다.<br />
③ "회원"이 결제 수단으로 신용카드를 사용할 경우 비밀번호 등 정보 유실
방지는 "회원" 스스로 관리해야 한다. 단, "사이트"의 결함에 따른
정보유실의 발생에 대한 책임은 회원의 의무에 해당하지 아니한다.<br />
④ "회원"은 서비스를 이용하여 얻은 정보를 "회사"의 사전동의 없이 복사,
복제, 번역, 출판, 방송 기타의 방법으로 사용하거나 이를 타인에게 제공할
수 없다.<br />
⑤ "회원"은 본 서비스를 건전한 직원채용 이외의 목적으로 사용해서는 안되며
이용 중 다음 각 호의 행위를 해서는 안 된다.<br />
1. 다른 회원의 아이디를 부정 사용하는 행위<br />
2. 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위<br />
3. 타인의 명예를 훼손하거나 모욕하는 행위<br />
4. 타인의 지적재산권 등의 권리를 침해하는 행위<br />
5. 해킹행위 또는 바이러스의 유포 행위<br />
6. 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 계속적으로 전송하는
행위<br />
7. 서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있다고 판단되는
행위<br />
8. 사이트의 정보 및 서비스를 이용한 영리 행위<br />
9. 그밖에 선량한 풍속, 기타 사회질서를 해하거나 관계법령에 위반하는
행위<br />
⑥ "회원"은 "사이트"를 통해 열람한 이력서 정보를 "회사" 및 당사자의
허락없이 재배포할 수 없으며 본 정보에 대한 출력 및 복사 등의 관리 책임은
전적으로 "회원"에게 있다.<br /><br />

<strong>제 20 조 ("회원"에 대한 평가)</strong><br /><br />
① “회사”와 개인회원의 서비스 업무 향상 등의 목적으로 “회원”의 업무를
업무평점, 업무배정 수, 개인회원 평가수 등의 기준으로 평가할 수 있고,
평가기준은 정책을 통해 변경될 수 있다.<br />
② “회원”에 대한 평가 결과가 “회사”가 정한 기준에 미달하는 경우, “회사”는
“회원”에 대한 업무 범위를 조정하거나 “회원”의 회사 프로그램(앱)의 접속
권한을 상실ㆍ제한 할 수 있다.<br />
③“회사”가 “회원”의 회사 프로그램(앱)의 접속 권한을 상실ㆍ제한할 경우
회사는 “회원”에게 전화, 문자메시지, 이메일, 배달 프로그램(앱), 회사가
정한 공지채널(SNS) 등으로 그 사유를 안내한다.<br /><br />

<strong>제 21 조 ("회원"의 가입해지/서비스중지/자료삭제)</strong
><br /><br />
① "회원"은 언제든지 회원탈퇴 또는 채용공고 등록을 해지하기 위해 고객센터
또는 회원탈퇴 메뉴 등을 통하여 이용계약 해지 신청을 할 수 있으며,
"회사"는 관련법 등이 정하는 바에 따라 이를 처리한다.<br />
② 다음의 사항에 해당하는 경우 "회사"는 사전 동의없이 가입해지나 서비스
중지, 채용공고 삭제 조치를 취할 수 있다.<br />
1. 회원의 의무를 성실하게 이행하지 않았을 때<br />
2. 규정한 유료서비스 이용 요금을 납부하지 않았을 때<br />
3. 불순한 의도로 유료서비스를 반복적으로 결제/취소할 때<br />
4. 본 서비스 목적에 맞지 않는 분야에 정보를 활용하여 사회적 물의가
발생한 때<br />
5. 회원이 등록한 정보의 내용이 사실과 다르거나 조작되었을 때<br />
6. 사업자등록증에 나타난 정보와 기업회원 가입시의 정보가 일치하지 않을
때<br />
7. 타 기업의 사업자등록번호나 상호를 도용하여 허위로 기업정보를
등록하거나 휴·폐업 사업자등록번호로 기업정보를 등록했을 때<br />
8. 지사, 지점, 영업소 등의 경우 구체적인 지사, 지점, 영업소 명칭을
사용하지 않고 기업명을 등록했거나 지사, 지점, 영업소의 사업자등록번호가
별도 있음에도 불구하고 본사의 사업자등록번호로 기업회원 가입을 했을
때<br />
9. 회사명, 담당자 성명, 사무실 연락처 등 구인업체의 중요 정보를 정확하게
기입하지 않았을 때<br />
10. 기업회원 가입 또는 채용공고 등록시 본 서비스에서 안내하는 방법으로
가입하지 않았거나 등록하지 않았을 때<br />
11. 동일 회원ID로 마감일이 지나지 않은 사실상의 동일 내용의 채용공고를
중복 등록했을 때<br />
12. 동일 사업자가 마감일이 지나지 않은 사실상의 동일내용의 채용공고를
다수 ID를 사용하여 중복등록 했을 때<br />
13. 허위 구인공고를 등록했을 때<br />
14. 작성자가 제시한 직종, 업무내용, 근로조건 등이 실제와 현저히 다를
때<br />
15. 채용공고의 모집요강만으로 업무에 대한 내용이 명확하지 않을 때<br />
16. 모집요강에 적합한 업직종 분류를 지정하지 않았을 때<br />
(특히, 교육기관이나 이벤트업체 또는 영업대행 기관의 경우 영업, 마케팅,
텔레마케팅, 상담 인력을 모집하고자 할 경우 반드시 텔레마케팅 또는
고객상담, 교육/강사/학원 등 반드시 직접 관련되는 해당 업종, 직종 분류만
지정해야 한다.)<br />
17. 헤드헌팅과 같이 인력중개를 주 목적으로 사업을 수행하는 업체인 경우
(jobkorea.co.kr 에서만 적용)<br />
18. 채용공고를 등록한 회원의 정보와 실제 채용공고 상 모집하는 기업의
정보가 다른 경우<br />
19. 아웃소싱(도급), 인재파견, 채용대행 공고임에도 불구하고 자사 공고인
것처럼 혼란을 야기하는 공고를 등록 한 때<br />
20. 다단계 식의 모집 내용을 등록했을 때<br />
21. 구인을 가장하여 유학알선, 물품판매, 자금 모금 등을 행하는 내용을
등록했을 때<br />
22. 회원모집 형태의 광고 또는 카드회원 모집(수당제 광고) 내용을 등록했을
때<br />
23. 추천인 모집 광고, 재택 쇼핑몰 분양 광고, 직업소개, 부업알선 광고를
등록했을 때<br />
24. 취업수수료 등 취업 관련 비용을 필요로 하는 채용공고를 등록했을 때
(운송, 물류, 지입, 인력용역 등)<br />
25. 불건전한 내용의 유흥업소 채용공고를 등록했을 때<br />
26. 구직자의 피해사례 (채용공고를 통해 구직자가 입사 과정에서 피해가
발생한 경우 또는 재직중 "기업회원"이 관계법령을 위반하여 피해가 발생한
경우 등)가 접수된 채용공고<br />
27. 임금체불로 근로감독원에 진정서가 접수되거나, 관계 당국에
고소·고발되어 있는 기업 (단, 기업의 임금체불이 해소가 입증되면 확인 후에
서비스 이용 가능)<br />
28. 본 서비스와 관련하여 회사 또는 제3자의 명예를 훼손하였을 때<br />
29. 동업자 모집, 프랜차이즈 모집, 점포개설, 창업, 사이트 홍보 등 직원
채용이 아닌 공고를 등록했을 때<br />
30. 기타 관계법규에 위배되는 내용이거나 사이트의 원활한 운영을 위하여
운영자가 필요하다고 판단한 경우<br />
③ "회원"이 유료서비스를 이용하는 중 "회사"의 책임에 의해 정상적인
서비스가 제공되지 않을 경우 "회원"은 본 서비스의 해지를 요청할 수 있으며
"회사"는 기간의 정함이 있는 유료서비스의 경우에는 해지일까지 이용일수를
1일 기준금액으로 계산하여 이용금액을 공제후 환급하고, 이용건수의 정함이
있는 유료서비스의 경우에는 기 사용분을 1건 기준금액으로 계산하여
이용금액을 공제후 환급한다. 단, 허위·불량공고의 경우 게재시 지불된
광고료는 일체 반환되지 않는다.<br />
④ “회원”이 이용계약 해지 신청시 수행하고 있는 업무가 있는 경우 혹은 정산
지급 할 예정인 급여가 남아있는 경우, ,해당업무를 완료하고 급여를 지급한
이후에 해지가 가능하다<br />
⑤ "회원"인 개인사업자와 법인사업자의 휴·폐업 여부가 회사가 정한 별도의
절차에 따라 확인된 경우 유료 이력서 검색서비스(건수별 인재검색)의 경우
적립된 건수는 모두 소멸된다.<br />
⑥ "회사"는 회원 가입이 해지된 경우 해당 회원 정보를 모두 삭제하여야
하며, 상법, 전자상거래등에서의 소비자보호에 관한 법률 등 관계법령의
규정에 의하여 보존할 필요가 있는 경우 관계법령에서 정한 일정한 기간 동안
회원정보를 보관한다.<br />
⑦ 개인정보보호를 위하여 "회원"이 선택한 개인정보 보유기간(1년, 3년,
회원탈퇴시) 동안 "사이트"를 이용하지 않은 경우, "아이디"를
"휴면계정"으로 분리하여 해당 계정의 이용을 중지할 수 있다. 이 경우
"회사"는 "휴면계정 처리 예정일"로부터 30일 이전에 해당사실을 전자메일,
서면, SMS 중 하나의 방법으로 사전통지하며 "회원"이 직접 본인확인을 거쳐,
다시 "사이트" 이용 의사표시를 한 경우에는 "사이트" 이용이 가능하다.
"휴면계정"으로 분리 보관된 개인정보는 5년간 보관 후 지체없이
파기한다.<br /><br />

<strong>제 22 조 (허위 구인광고 경고)</strong><br /><br />
허위 구인광고로 인해 발생된 문제에 따른 법적인 책임은 모두 작성자에게
있으며, 구인광고 게재시에 지불된 광고료는 일체 반환되지 않는다. 허위
구인광고 또는 허위 구인 조건을 제시한 자는 직업 안정법 제 47조에 의해
5년 이하의 징역 또는 2천만원 이하의 벌금형을 받게 된다.<br /><br />
<strong>제 23 조 (보안 및 비밀유지등)</strong><br /><br />
① “회사”는 본 약관을 통하여 습득한 “개인회원”에 관한 정보나 자료를
“개인회원”의 사전 동의 없이 제3자에게 제공, 공표 또는 누설하여서는 안
된다..<br />
② “회원”은 본 약관을 통하여 습득한 “개인” 또는 회사의 고객에 관한 정보나
자료를 회사의 사전 동의 없이 녹취(녹음), 사진촬영, 복제, 저장하거나,
제3자에게 제공, 공표 또는 누설하여서는 안 된다.<br />
③ “회원”이 본 약관에 따른 업무 목적을 위해서만 회사의 프로그램(앱)을
사용하여야 하며, 그 과정에서 회사 또는 회사의 고객 정보가 제3자에게
유출되지 않도록 보안 유지의무를 다하여야 합니다.<br />
④ “회원”은 “회사”의 상호, 상표 및 기타 특정될 수 있는 표지를 “회사”의
사전 동의 없이 본 약관의 이행을 위한 목적 외로 사용하여서는 안 된다.<br />
⑤ 본 약관이 종료하는 경우, “회원”은 회사로부터 제공받은 정보 및 자료를
회사의 요청에 따라 반환하고, 반환이 불가능한 경우 복원이 불가능한
방법으로 파기하여야 한다.<br />
⑥ 본 조에 따른 의무는 본 약관이 종료된 후에도 유효하게 적용됩니다.<br /><br />

<strong>제 25 조 (손해배상)</strong><br /><br />
① "회사"가 이 약관의 규정을 위반한 행위로 "회원"에게 손해를 입히거나
기타 "회사"가 제공하는 모든 서비스와 관련하여 "회사"의 책임 있는 사유로
인해 "회원"에게 손해가 발생한 경우 "회사"는 그 손해를 배상하여야
한다.<br />
② "회사"는 책임 있는 사유로 제공한 정보가 사실과 달라 "회원"이 손해를
입었을 경우에 "회사"는 그 손해를 배상하여야 한다.<br />
③ "회원"이 이 약관의 규정을 위반한 행위로 "회사" 및 제3자에게 손해를
입히거나 "회원"의 책임 있는 사유에 의해 "회사" 및 제3자에게 손해를 입힌
경우에는 "회원"은 그 손해를 배상하여야 한다.<br />
④ 타 회원(개인회원, 기업회원, 서치펌회원 모두 포함)의 귀책사유로
"회원"의 손해가 발생한 경우 "회사"는 이에 대한 배상 책임이 없다.<br /><br />

<strong>제 26 조 (양도 금지)</strong><br /><br />
"회원"의 서비스 받을 권리는 제3자에게 양도, 대여, 증여 등으로 사용할 수
없다.<br /><br />

<strong>제 27 조 (이용요금 오류의 조정)</strong><br /><br />
"회사"는 이용요금과 관련하여 오류가 있는 경우에 "회원"의 요청, 또는
"회사"의 사전 통지에 의하여 다음에 해당하는 조치를 취한다.<br />
1. 과다납입한 요금에 대하여는 그 금액을 반환한다. 다만, "회원"이 동의할
경우 다음 달에 청구할 요금에서 해당 금액만큼을 감하여 청구한다.<br />
2. 요금을 반환받아야 할 "회원"이 요금체납이 있는 경우에는 반환해야 할
요금에서 이를 우선 공제하고 반환한다.<br />
3. "회사"는 과소청구액에 대해서는 익월에 합산청구한다.<br /><br />

<strong>제 27 조 ("회원"의 개인정보보호)</strong><br /><br />
"회사"는 "회원"의 개인정보보호를 위하여 노력해야 한다. "회원"의
개인정보보호에 관해서는 정보통신망이용촉진 및 정보보호 등에 관한 법률,
개인정보보호법에 따르고, "사이트"에 "개인정보처리방침"을 고지한다.<br /><br />

<strong>제 28 조 (신용정보의 제공 활용 동의)</strong> <br /><br />
① "회사"가 회원가입과 관련하여 취득한 "회원"의 신용정보를 타인에게
제공하거나 활용하고자 할 때에는 신용정보의 이용 및 보호에 관한 법률
제23조의 규정에 따라 사전에 그 사유 및 해당기관 또는 업체명 등을 밝히고
해당 "회원"의 동의를 얻어야 한다.<br />
② 본 서비스와 관련하여 "회사"가 "회원"으로부터 신용정보의 이용 및 보호에
관한 법률에 따라 타인에게 제공 활용에 동의를 얻은 경우 "회원"은 "회사"가
신용정보 사업자 또는 신용정보 집중기관에 정보를 제공하여 "회원"의 신용을
판단하기 위한 자료로 활용하거나, 공공기관에서 정책자료로 활용되도록
정보를 제공하는 데 동의한 것으로 간주한다.<br /><br />

<strong>제 29 조 (분쟁의 해결)</strong><br /><br />
① "회사"와 "회원"은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기
위하여 필요한 모든 노력을 하여야 한다.<br />
② 전항의 노력에도 불구하고, 동 분쟁에 관한 소송은 "회사"의 주소지
관할법원으로 한다.<br /><br />

부칙<br /><br />

- 이 약관은 2021년 10월 25일부터 시행한다.`

export const personal = `<strong> 제 1 조 (목적)</strong><br /><br />
본 약관은 주식회사 탑스맨(이하 "회사")가 제공하는 "호구153(hogoo153)"과
관련 제반 서비스를 이용함에 있어 "회사"와 회원간의 권리, 의무 및
책임사항, 이용 조건 및 제반 절차, 기타 필요한 사항을 규정함을 목적으로
한다.<br /><br />

<strong>제 2 조 (용어의 정의)</strong><br /><br />
본 약관에서 사용하는 용어의 정의는 아래와 같다<br /><br />
① 사이트 : 회사가 서비스를 "회원"에게 제공하기 위하여 컴퓨터 등 정보통신
설비를 이용하여 설정한 가상의 영업장 또는 회사가 운영하는 웹사이트,
모바일웹, 앱 등의 서비스를 제공하는 모든 매체를 통칭하며, 통합된 하나의
회원 계정(아이디 및 비밀번호)을 이용하여 서비스를 제공받을 수 있는
아래의 사이트를 말한다.<br /><br />
- www.hogoo153.com <br />
- www.hogoo153.co.kr<br />
- www.hogoo153.net<br />
- www.hogoo153.kr<br />
- www.호구153.com<br /><br />
② 서비스 : 회사가 운영하는 사이트를 통해 개인이 구직, 교육 등의 목적으로
등록하는 자료를 DB화하여 각각의 목적에 맞게 분류 가공, 집계하여 정보를
제공하는 서비스와 사이트에서 제공하는 모든 부대 서비스를 말한다.<br />
③ 회원 : 회사가 제공하는 서비스를 이용하거나 이용하려는 자로, NAVER 등
외부 서비스 연동을 통해 "회사"와 이용계약을 체결한 자 또는 체결하려는
자를 포함하며, 아이디와 비밀번호의 설정 등 회원가입 절차를 거쳐
회원가입확인 이메일 등을 통해 회사로부터 회원으로 인정받은 "개인회원"을
말한다.<br />
④ 아이디 : 회원가입 시 회원의 식별과 회원의 서비스 이용을 위하여
"회원"이 선정하고 "회사"가 부여하는 문자와 숫자의 조합을 말한다.<br />
⑤ 비밀번호 : 위 제4항에 따라 회원이 회원 가입시 아이디를 설정하면서
아이디를 부여받은 자와 동일인임을 확인하고 "회원"의 권익을 보호하기
위하여 "회원"이 선정한 문자와 숫자의 조합을 말한다.<br />
⑥ 비회원 : 회원가입 절차를 거치지 않고 "회사"가 제공하는 서비스를
이용하거나 하려는 자를 말한다.<br />
⑦ 인증서 : 인증서라 함은 회사가 인증서비스를 통하여 발급하는
전자서명생성정보가 회원에게 유일하게 속한다는 사실 등을 확인하고 이를
증명하는 전자적 정보를 말한다..<br />
⑧ 전자서명 : 서명자의 신원을 확인하고 서명자가 해당 전자문서에
서명하였다는 사실을 나타내는데 이용하기 위하여 전자문서에 첨부되거나
논리적으로 결합된 전자적 형태의 정보를 말합니다.<br />
⑨ 전자서명생성정보 : 전자서명을 생성하기 위하여 이용하는 전자적 정보를
말한다.<br />
⑩ 인증회원 : 회사로부터 전자서명생성정보를 인증 받은 회원을 말합니다.<br />
⑪ 이용기관 : 인증회원의 전자서명 및 인증서를 바탕으로 한 거래 등을
위하여 인증서비스를 이용하려는 제3자를 말합니다. <br /><br />

<strong>제 3 조 (약관의 명시와 개정)</strong><br /><br />
① "회사"는 본 약관의 내용과 상호, 영업소 소재지, 대표자의 성명,
사업자등록번호, 연락처 등을 "회원"이 알 수 있도록 초기 화면에 게시하거나
기타의 방법으로 "회원"에게 공지해야 한다.<br />
② "회사"는 약관의 규제에 관한 법률, 전기통신기본법, 전기통신사업법,
정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관련법을 위반하지 않는
범위에서 본 약관을 개정할 수 있다.<br />
③ "회사"가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여
“회원”에게 현행약관과 함께 그 개정약관의 적용일자 7일 전부터 적용일자
전일까지 전화,문자메세지,이메일, 앱푸시(App PUSH),게시사항 공지 등의
방법으로 공지한다. 단 "회원"의 권리, 의무에 중대한 영향을 주는 변경의
경우에는 적용일자 30일 전부터 공지하도록 한다.<br />
④ "회원"은 변경된 약관에 대해 거부할 권리가 있다. "회원"은 변경된 약관이
공지된 지 15일 이내에 거부의사를 표명할 수 있다. "회원"이 거부하는 경우
본 서비스 제공자인 "회사"는 15일의 기간을 정하여 "회원"에게 사전 통지 후
당해 "회원"과의 계약을 해지할 수 있다. 만약, "회원"이 거부의사를
표시하지 않거나, 전항에 따라 시행일 이후에 "서비스"를 이용하는 경우에는
동의한 것으로 간주한다.<br /><br />

<strong>제 4 조 (약관의 해석)</strong><br /><br />
① 본 약관에서 규정하지 않은 사항에 관해서는 약관의 규제에 관한 법률,
전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에
관한 법률 등의 관계법령에 따른다.<br />
② 각 사이트 및 서비스 이용약관이 있는 경우에는 서비스 이용약관이
우선한다.<br />
③ "회원"이 "회사"와 개별 계약을 체결하여 서비스를 이용하는 경우에는 개별
계약이 우선한다.<br /><br />

<strong>제 5 조 (이용계약의 성립)</strong><br /><br />
① "회사"의 서비스 이용계약(이하 "이용계약")은 서비스를 이용하고자 하는
자가 본 약관과 개인정보처리방침을 읽고 "동의" 또는 "확인" 버튼을 누른
경우 본 약관에 동의한 것으로 간주한다.<br />
② 제1항 신청에 있어 "회사"는 "회원"의 종류에 따라 전문기관을 통한
실명확인 및 본인인증을 요청할 수 있으며, "회원"은 본인인증에 필요한
이름, 생년월일, 연락처 등을 제공하여야 한다.<br />
③ "회원"이 이용신청(회원가입 신청) 작성 후에 "회사"가 웹상의 안내 및
전자메일로 "회원"에게 통지함으로써 이용계약이 성립된다.<br />
④ 페이스북 등 외부 서비스와의 연동을 통해 이용계약을 신청할 경우, 본
약관과 개인정보취급방침, 서비스 제공을 위해 "회사"가 "회원"의 외부
서비스 계정 정보 접근 및 활용에 "동의" 또는 "확인" 버튼을 누르면
"회사"가 웹상의 안내 및 전자메일로 "회원"에게 통지함으로써 이용계약이
성립된다.<br /><br />

<strong>제 6 조 (이용신청의 승낙과 제한)</strong><br /><br />
① "회사"는 전조의 규정에 의한 이용신청 고객에 대하여 업무 수행상 또는
기술상 지장이 없는 경우에는 원칙적으로 접수순서에 따라 서비스 이용을
승낙한다.<br />
② "회사"는 아래사항에 해당하는 경우에 대해서는 서비스 이용신청을
승낙하지 아니한다.<br />
1. 실명이 아니거나 타인의 명의를 이용하여 신청한 경우<br />
2. 이용계약 신청서의 내용을 허위로 기재하거나 "회사"가 제시하는 내용을
기재하지 않은 경우<br />
3. 만 15세 미만의 아동이 신청한 경우. 다만, 만 13세 이상 만 15세 미만의
아동으로서 노동부장관 발급의 취직인허증이 있는 경우에는 그러하지
아니한다.<br />
4. 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반
사항을 위반하여 신청하는 경우<br />
③ "회사"는 아래사항에 해당하는 경우에는 그 신청에 대하여 승낙제한 사유가
해소될 때까지 승낙을 유보할 수 있다.<br />
1. "회사"가 설비의 여유가 없는 경우<br />
2. "회사"의 기술상 지장이 있는 경우<br />
3. 기타 "회사"의 귀책사유로 이용승낙이 곤란한 경우<br /><br />

<strong>제 7 조 (서비스의 내용)</strong><br /><br />
① "회사"는 제2조 2항의 서비스를 제공할 수 있으며 그 내용은 다음 각 호와
같다.<br />
1. 이력서 등록 및 인재정보 게재 서비스<br />
2. 온라인 입사지원 서비스<br />
3. 헤드헌팅/아웃소싱 서비스<br />
4. 구인/구직과 관련된 제반 서비스<br />
5. 교육과 관련된 제반 서비스<br />
6. 이용자 간의 교류와 소통에 관련한 서비스<br />
7. 자료거래에 관련한 서비스<br />
8. 구인자, 구직자 상호 평가 및 이용후기 서비스<br />
9. 일자리 매칭서비스<br />
10. 위치기반 근태관리 서비스<br />
11. 위치정보에 관한 서비스 <br />
12. 기타"회사"가 추가 개발, 편집, 재구성하거나 제휴계약 등을 통해
“회원”에게 제공하는 일체의 서비스<br />
② "회사"는 필요한 경우 서비스의 내용을 추가 또는 변경할 수 있다. 단, 이
경우 "회사"는 추가 또는 변경내용을 "회원"에게 공지해야 한다.<br /><br />

<strong>제 8 조 (회원, 이력서 정보)</strong><br /><br />
① "회원"의 이력서는 개인이 회원가입 또는 이력서 작성 및 수정시 희망한
형태로 등록 및 제공된다.<br />
② "회사"는 "회원"이 이력서의 인재정보 등록/미등록 지정, 이력서상의
연락처 제공 여부를 자유롭게 선택할 수 있도록 하여야 한다.<br />
③ "회사"는 "회원"이 이력서의 인재정보 등록 및 제공을 희망했을 경우,
기업회원의 이력서 열람 및 각종 포지션 제안에 동의한 것으로 간주하며
"회사"는 기업회원에게 유료로 이력서 열람 서비스를 제공할 수 있다. 다만,
연락처 각 항목이 비공개로 지정된 경우 해당 항목별 연락처를 노출할 수
없다.<br />
④ "회사"는 안정적인 서비스를 제공하기 위해 테스트 및 모니터링 용도로
"사이트" 운영자가 이력서 정보를 열람하도록 할 수 있다.<br />
⑤ "회사"는 "회원"의 선택에 의하여 등록 및 제공되는 이력서의 정보를
기준으로 구직활동에 보다 유익한 서비스를 제공하기 위하여 이를 개발,
편집, 재구성한 통계 자료로 활용 할 수 있다.<br /><br />

<strong>제 9 조 (제휴를 통한 서비스)</strong><br /><br />
① "회사"는 제휴 관계를 체결한 여타 인터넷 웹 사이트 및 채용박람회 또는
신문, 잡지 등의 오프라인 매체를 통해 사이트에 등록한 "회원"의 이력서
정보가 열람될 수 있도록 서비스를 제공할 수 있다. 단, 제휴 서비스를 통해
노출되는 이력서의 연락처 정보는 “회원”이 이력서 등록 시 선택한 연락처
공개 여부에 따라 제공된다.<br />
② "회사"는 제휴를 통해 타 사이트 및 매체에 등록될 수 있음을 고지해야
하며, 제휴 사이트 목록을 사이트내에서 상시 열람할 수 있도록 해야 한다.
단, 등록의 형태가 "회사"가 직접 구축하지 않고, 제휴사가 xml 또는 api
형태로 "회사"로부터 제공 받아 구축한 매체 목록은 본 약관외 별도의
제휴리스트에서 열람할 수 있도록 한다.<br />
③ "회사"는 "회원"이 공개를 요청한 이력서에 한해 제휴를 맺은 타 사이트에
이력서 정보를 제공한다. (본 약관 시행일 현재에는 제휴를 통한 타 사이트
및 매체는 없다.)<br />
④ 본 조 제 3항 제휴를 통한 사이트의 변동사항 발생 시 공지사항을 통해
고지 후 진행 한다.<br /><br />

<strong>제 10 조 (서비스의 요금)</strong><br /><br />
① "회원" 가입과 이력서 등록은 무료이다. 다만 기업회원 또는 사이트에
방문한 기업체에게 자신의 이력서 정보를 보다 효과적으로 노출시키기 위한
유료서비스 및 인성, 적성 검사 등 회원 가입 목적 외 기타 서비스를
이용하기 위한 별도의 서비스는 유료로 제공될 수 있다.<br />
② "회사"는 유료서비스를 제공할 경우 사이트에 요금에 대해서 공지를 하여야
한다.<br />
③ "회사"는 유료서비스 이용금액을 서비스의 종류 및 기간에 따라 "회사"가
예고 없이 변경할 수 있다. 다만, 변경 이전에 적용 또는 계약한 금액은
소급하여 적용하지 아니한다.<br />
④“회사”는 “회원” 업무 완료 건 당 다음 각 호의 기준에 따라 급여를
지급한다. 이때, 급여 산정기준은 프로그램(앱), 회사가 정한 공지채널(SNS)
등을 통해 사전에 공지하며, 변경이 있을 시 즉시 공지한다.<br />
1. 기본 급여(세전 금액 기준) : 기업회원이 정한 조건으로 거래확정 시
산정<br />
2. 기타 급여: 긴급상황, 지역, 거리, 날씨 등을 고려하여 산정.<br />
⑤ “회사”는 급여 외에 별도의 프로모션에 따라 포인트를 지급할 수 있으며,
위 프로모션은 “회원”에 대한 전화, 문자메시지, 이메일, 프로그램(앱),
회사가 정한 공지채널(SNS) 등의 방법으로 실시간 공지될 수 있다.<br />
⑥“회사”는 “회원”에게 업무 배정시 기업회원이 정한 해당 건으로 “회원”에게
지급할 급여 예상액을 제시합니다.<br />
⑦“회원”이 업무 과정에서 고객 또는 기업회원의 귀책사유로 인해 업무에
곤란을 겪을 경우 회사는 회사가 정한 기준에 따라 급여를 추가 지급할 수
있다.<br />
⑧ 본 조에 의한 급여 등은 “회원”의 사업소득에 해당하여, 회사는 소득세법
제127조 제1항 제3호, 동법 시행령 제184조 제1항 등(본 약관 효력 발생 이후
각 법령이 개정되는 경우에는 개정 후의 해당 조항)에서 정한 바에 따라 본
조에 따른 급여 등의 일부(3.3%)를 원천징수 한다.<br />
⑨ “회사”는 1) 매월 1일부터 7일, 2) 8일부터 14일, 3) 15일부터21일, 4)
22일부터 말일 사이에 거래 확정 된 거에 대하여, 각 기간 만료일로부터 7일
후까지 “회원”에게 “회원”이 지정한 본인 명의계좌로 수수료 등을
지급합니다. 단, 지급 기한일이 토요일 또는 공휴일인 경우, 그 직후의 은행
영업일까지 지급한다.<br />
⑩ 회사는 “회원”에게 수수료 지급내역을 교부하거나 “회원” 요청 시 상시
열람할 수 있도록 협조한다.<br /><br />

<strong>제 11 조 (이용요금 오류의 조정)</strong><br /><br />
"회사"는 이용요금과 관련하여 오류가 있는 경우에 "회원"의 요청, 또는
"회사"의 사전 통지에 의하여 다음에 해당하는 조치를 취한다.<br />
1. 과다 납입한 요금에 대하여는 그 금액을 반환한다. 다만, "회원"이 동의할
경우 다음에 청구할 요금에서 해당 금액만큼을 감하여 청구한다.<br />
2. 요금을 반환받아야 할 "회원"이 요금체납이 있는 경우에는 반환해야 할
요금에서 이를 우선 공제하고 반환한다.<br />
3. "회사"는 과소청구액에 대해서는 다음에 합산 청구한다.<br /><br />

<strong>제 12조 (서비스 요금의 환불)</strong><br /><br />
① "회사"는 다음 각 호에 해당하는 경우 이용요금을 환불한다. 단, 각
당사자의 귀책사유에 따라 환불 조건이 달라질 수 있다.<br />
1. 유료서비스 이용이 개시되지 않은 경우<br />
2. 네트워크 또는 시스템 장애로 서비스 이용이 불가능한 경우<br />
3. 유료서비스 신청 후 "회원"의 사정에 의해 서비스가 취소될 경우<br />
② "회사"가 본 약관 제19조에 따라 가입해지/서비스중지/자료삭제를 취한
경우, "회사"는 "회원"에게 이용요금을 환불하지 않으며, 별도로 "회원"에게
손해배상을 청구할 수 있다.<br />
③ 이용료를 환불받고자 하는 회원은 고객센터로 환불을 요청해야 한다.<br />
④ "회사"는 환불 요건에 부합하는 것으로 판단될 경우, 각 서비스 환불
안내에 따라 유료이용 계약 당시 상품의 정가 기준으로 서비스 제공된 기간에
해당하는 요금을 차감한 잔액을 환불한다.<br /><br />

<strong>제 13 조 (서비스 이용시간)</strong><br /><br />
① "회사"는 특별한 사유가 없는 한 연중무휴, 1일 24시간 서비스를 제공한다.
다만, "회사"는 서비스의 종류나 성질에 따라 제공하는 서비스 중 일부에
대해서는 별도로 이용시간을 정할 수 있으며, 이 경우 "회사"는 그
이용시간을 사전에 "회원"에게 공지 또는 통지하여야 한다.<br />
② "회사"는 자료의 가공과 갱신을 위한 시스템 작업시간, 장애 해결을 위한
보수작업 시간, 정기 PM작업, 시스템 교체작업, 회선 장애 등이 발생한 경우
일시적으로 서비스를 중단할 수 있으며 계획된 작업의 경우 공지란에 서비스
중단 시간과 작업 내용을 알려야 한다. 다만, "회사"가 사전에 통지할 수
없는 부득이한 사유가 있는 경우 사후에 통지할 수 있다.<br /><br />

<strong>제 14 조 (서비스 제공의 중지)</strong><br /><br />
① "회사"는 다음 각 호에 해당하는 경우 서비스의 제공을 중지할 수 있다.<br />
1. 설비의 보수 등 "회사"의 필요에 의해 사전에 "회원"들에게 통지한
경우<br />
2. 기간통신사업자가 전기통신서비스 제공을 중지하는 경우<br />
3. 기타 불가항력적인 사유에 의해 서비스 제공이 객관적으로 불가능한
경우<br />
② 전항의 경우, "회사"는 기간의 정함이 있는 유료서비스 이용자들에게는 그
이용기간을 연장하거나 환불 등의 방법으로 손실을 보상하여야 한다.<br /><br />

<strong>제 15 조 (정보의 제공 및 광고의 게재)</strong><br /><br />
① "회사"는 "회원"에게 서비스 이용에 필요가 있다고 인정되거나 서비스 개선
및 회원 대상의 서비스 소개 등의 목적으로 하는 각종 정보에 대해서
전자우편이나 서신우편을 이용한 방법으로 제공할 수 있다.<br />
⓶ “회사”는 “회원”에게 서비스 이용•개선을 위해 필요한 사항을 알리거나
각종 상품•서비스 소개, 홍보 등을 위한 정보를 우편물, 이메일이나
애플리케이션 푸쉬 알림, 모바일 장치, IoT 등 신종 기술 및 기기를 이용한
방법으로 제공할 수 있습니다.<br />
⓷ "회사"는 제공하는 서비스와 관련되는 정보 또는 광고를 서비스 화면,
홈페이지, 전자우편 등에 게재할 수 있으며, 광고가 게재된 전자우편을
수신한 "회원"은 수신거절을 "회사"에게 할 수 있다.<br />
④ "회사"는 서비스상에 게재되어 있거나 본 서비스를 통한 광고주의
판촉활동에 "회원"이 참여하거나 교신 또는 거래를 함으로써 발생하는 모든
손실과 손해에 대해 책임을 지지 않는다.<br />
⑤ 본 서비스의 "회원"은 서비스 이용 시 노출되는 광고게재에 대해 동의 하는
것으로 간주한다.<br /><br />

<strong>제 16 조 (자료내용의 책임과 "회사"의 정보 수정 권한)</strong
><br /><br />
① 자료내용은 "회원"이 등록한 개인정보 및 이력서와 사이트에 게시한
게시물을 말한다.<br />
② "회원"은 자료 내용 및 게시물을 사실에 근거하여 성실하게 작성해야 하며,
만일 자료의 내용이 사실이 아니거나 부정확하게 작성되어 발생하는 모든
책임은 "회원"에게 있다.<br />
③ 모든 자료내용의 관리와 작성은 "회원" 본인이 하는 것이 원칙이나 사정상
위탁 또는 대행관리를 하더라도 자료내용의 책임은 "회원"에게 있으며
"회원"은 주기적으로 자신의 자료를 확인하여 항상 정확하게 관리가 되도록
노력해야 한다.<br />
④ "회사"는 "회원"이 등록한 자료 내용에 오자, 탈자 또는 사회적 통념에
어긋나는 문구와 내용이 있을 경우 이를 언제든지 수정할 수 있다.<br />
⑤ “회사”는 “회원”이 작성한 콘덴츠가 다음 각 호에 해당한다고 판단하는
경우,이를 삭제하거나 게시를 거부할 수 있다.<br />
1. 음란 게시물<br />
2. 청소년에게 유해한 게시물<br />
3. 차별•갈등을 조장하는 게시물<br />
4. 도배 광고 또는 홍보 스팸성 게시물<br />
5. 계정의 양도나 거래를 내용으로 하는 게시물<br />
6. 타인을 사칭하는 게시물<br />
7. 기타 법령에 위반되거나 그에 준하는 게시물<br />
⑥ "회원"이 등록한 자료로 인해 타인(또는 타법인)으로부터 허위사실 및
명예훼손 등으로 삭제 요청이 접수된 경우 "회사"는 "회원"에게 사전 통지
없이 본 자료를 삭제할 수 있으며 삭제 후 메일 등의 방법으로 통지할 수
있다.<br /><br />

<strong>제 17 조 (자료 내용의 활용 및 취급)</strong><br /><br />
① "회원"이 선택하거나 입력한 정보는 취업 및 관련 동향의 통계 자료로
구성, 활용될 수 있으며 그 자료는 매체를 통한 언론 배포 또는 제휴사에게
제공될 수 있다. 단, 개인을 식별할 수 있는 형태가 아니어야 한다.<br />
② 제8조 제3항에 따라 "사이트"에서 정당한 절차를 거쳐 기업회원이 열람한
"회원"의 이력서 정보는 해당 기업의 인사자료이며 이에 대한 관리 권한은
해당 기업의 정책에 의한다.<br />
③ "회사"는 "사이트"의 온라인 입사지원 시스템을 통해 지원한 "회원"의
이력서 열람 여부 및 기업회원이 제공한 채용정보에 입사지원한 구직자들의
각종 통계데이터를 "회원"에게 제공할 수 있다.<br /><br />

<strong>제 18 조 ("회사"의 의무)</strong><br /><br />
① "회사"는 본 약관에서 정한 바에 따라 계속적, 안정적으로 서비스를 제공할
수 있도록 최선의 노력을 다해야 한다.<br />
② "회사"는 서비스와 관련한 "회원"의 불만사항이 접수되는 경우 이를 즉시
처리하여야 하며, 즉시 처리가 곤란한 경우에는 그 사유와 처리일정을 서비스
화면 또는 기타 방법을 통해 동 "회원"에게 통지하여야 한다.<br />
③ "회사"는 유료 결제와 관련한 결제 사항 정보를 1년 이상 보존한다. 다만
회원 자격이 없는 회원은 예외로 한다.<br />
④ 천재지변 등 예측하지 못한 일이 발생하거나 시스템의 장애가 발생하여
서비스가 중단될 경우 이에 대한 손해에 대해서는 "회사"가 책임을 지지
않는다. 다만 자료의 복구나 정상적인 서비스 지원이 되도록 최선을 다할
의무를 진다.<br />
⑤ "회원"의 자료를 본 서비스 이외의 목적으로 제3자에게 제공하거나
열람시킬 경우 반드시 "회원"의 동의를 얻어야 한다.<br /><br />

<strong>제 19 조 ("회원"의 의무)</strong><br /><br />
① "회원"은 관계법령과 본 약관의 규정 및 기타 "회사"가 통지하는 사항을
준수하여야 하며, 기타 "회사"의 업무에 방해되는 행위를 해서는 안 된다.<br />
② "회원"이 신청한 유료서비스는 등록 또는 신청과 동시에 "회사"와 채권,
채무 관계가 발생하며, "회원"은 이에 대한 요금을 지정한 기일 내에
납부하여야 한다.<br />
③ "회원"이 결제 수단으로 신용카드를 사용할 경우 비밀번호 등 정보 유실
방지는 "회원" 스스로 관리해야 한다. 단, "사이트"의 결함에 따른
정보유실의 발생에 대한 책임은 "회원"의 의무에 해당하지 아니한다.<br />
④ "회원"은 서비스를 이용하여 얻은 정보를 "회사"의 사전동의 없이 복사,
복제, 번역, 출판, 방송 기타의 방법으로 사용하거나 이를 타인에게 제공할
수 없다.<br />
⑤ "회원"은 본 서비스를 건전한 취업 및 경력관리 이외의 목적으로
사용해서는 안되며 이용 중 다음 각 호의 행위를 해서는 안 된다.<br />
1. 다른 회원의 아이디를 부정 사용하는 행위<br />
2. 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위<br />
3. 타인의 명예를 훼손하거나 모욕하는 행위<br />
4. 타인의 지적재산권 등의 권리를 침해하는 행위<br />
5. 해킹행위 또는 바이러스의 유포 행위<br />
6. 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 계속적으로 전송하는
행위<br />
7. 서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있다고 판단되는
행위<br />
8. 사이트의 정보 및 서비스를 이용한 영리 행위<br />
9. 그밖에 선량한 풍속, 기타 사회질서를 해하거나 관계법령에 위반하는
행위<br /><br />

<strong>제 20 조 ("회원"에 대한 평가)</strong><br /><br />
① “회사”와 기업회원은 서비스 품질 향상 등의 목적으로 “회원”의 업무를
업무평점, 수락률, 교육 참여현황 등의 기준으로 평가할 수 있고, 평가기준은
정책을 통해 변경될 수 있다.<br />
② “회원”에 대한 평가 결과가 “회사”가 정한 기준에 미달하는 경우, “회사”는
“회원”에 대한 업무 범위를 조정하거나 “회원”의 회사 프로그램(앱)의 접속
권한을 상실ㆍ제한 할 수 있다.<br />
③“회사”가 “회원”의 회사 프로그램(앱)의 접속 권한을 상실ㆍ제한할 경우
회사는 “회원”에게 전화, 문자메시지, 이메일, 배달 프로그램(앱), 회사가
정한 공지채널(SNS) 등으로 그 사유를 안내한다.<br /><br />

<strong>제 21 조 ("회원"의 권익보호)</strong><br /><br />
① “회사”는 “회원”이 본 계약 및 관련 법령에 따라, 안전하게 용역을
제공하고 정당한 대가를 지급받을 수 있도록 “회원”의 권익보호를 위해
노력한다.<br />
② “회사”는 정당한 사유없이 “회원”에게 지급하는 것으로 확정된 급여를
감액하지 않는다.<br />
③ “회사”는 정당한 사유없이 급여의 전부 또는 일부의 지급을 지연하거나
거부 또는 지급한 급여를 환수하지 않는다.<br />
④ “회사”는 “회원”에 대한 평가를 진행하는 경우, 객관적인 기준에 따라
공정하게 평가합한다.<br />
⑤ “회사”는 정당한 사유없이 “회원”에게 특정한 업무 또는 본 약관에서
정하지 않은 업무를 강요하지 않는다.<br />
⑥ “회사”는 “회원”의 과실 등에 의하지 않은 손해배상 책임을 전가하기 위해
“회원”에게 과실 여부에 대한 인정 등을 강요하지 않는다.<br />
⑦ “회사”와 “회원”은 정당한 사유없이 합의된 제반 비용 이 외의 금전지급을
상호간에 요구할 수 없다.<br />
⑧ “회사”는 “회원”의 성별, 종교, 장애, 노동조합 가입 등을 이유로 하여
합리적인 사유없이 업무조건을 차별하거나 그 밖의 불리한 조치를 하지
않습니다.<br /><br />

<strong>제 22 조 ("회사"와 “회원” 간 관계)</strong><br /><br />
1.“회사”와 “회원” 사이에는 자회사 또는 계열사, 파트너쉽, 고용 또는
대리인 관계가 존재 하지 않는다.<br />
2.“회사”는 “회원”에게 어떠한 독점적 권리나 특정한 업무량을 보장하지
않는다<br />
3.“회원”은 “회사”에게 어떠한 독점적 권리나 일정한 업무량을 보장하지
않는다.<br />

<strong>제 23 조 ("회원"의 가입해지/서비스중지/자료삭제)</strong
><br /><br />
① "회원"은 언제든지 회원탈퇴 또는 이력서 등록을 해지하기 위해 고객센터
또는 회원탈퇴 메뉴 등을 통하여 이용계약 해지 신청을 할 수 있으며,
"회사"는 관련법 등이 정하는 바에 따라 이를 처리한다.<br />
② 다음의 사항에 해당하는 경우 "회사"는 사전 동의없이 가입해지나 서비스
중지, 이력서 삭제 조치를 취할 수 있다.<br />
1. 회원의 의무를 성실하게 이행하지 않았을 때<br />
2. 규정한 유료서비스 이용 요금을 납부하지 않았을 때<br />
3. 본 서비스 목적에 맞지 않는 분야에 정보를 활용하여 사회적 물의가
발생한 때<br />
4. 회원이 등록한 정보의 내용이 사실과 다르거나 조작되었을 때<br />
5. 본 서비스와 관련하여 회사 또는 제3자의 명예를 훼손하였을 때<br />
6. 기타 위 각호에 준하는 사유가 발생하였을 때<br />
③ "회원"이 유료서비스를 이용하는 중 "회사"의 책임에 의해 정상적인
서비스가 제공되지 않을 경우 "회원"은 본 서비스의 해지를 요청할 수 있으며
"회사"는 기간의 정함이 있는 유료서비스의 경우에는 해지일까지 이용일수를
1일 기준금액으로 계산하여 이용금액을 공제 후 환급하고, 이용건수의 정함이
있는 유료서비스의 경우에는 기 사용분을 1건 기준금액으로 계산하여
이용금액을 공제후 환급한다.<br />
④ “회원”이 이용계약 해지 신청시 수행하고 있는 업무가 있는 경우 혹은 정산
지급 받을 예정인 급여가 남아있는 경우, ,해당업무를 완료하고 급여를 지급
받은 이후에 해지가 가능하다<br />
⑤ 이용계약이 해지되는 경우, “회사”는 “회원”에게 부여된 모든 혜택을
철회할 수 있으며, 해지사유 및 해당 해지 사유의 해소 여부를 고려하여
“회원”의 재등록을 거부할 수 있다. <br />
④ "회사"는 회원 가입이 해지된 경우에는 개인정보처리방침 중,
04.개인정보의 보유 및 이용기간 규정에 따른다.<br />
⑤ 개인정보보호를 위하여 "회원"이 선택한 개인정보 보유기간(1년, 3년,
회원탈퇴시) 동안 "사이트"를 이용하지 않은 경우, "아이디"를
"휴면계정"으로 분리하여 해당 계정의 이용을 중지할 수 있다. 이 경우
"회사"는 "휴면계정 처리 예정일"로부터 30일 이전에 해당사실을 전자메일,
서면, SMS 중 하나의 방법으로 사전통지하며 "회원"이 직접 본인확인을 거쳐,
다시 "사이트" 이용 의사표시를 한 경우에는 "사이트" 이용이 가능하다.
"휴면계정"으로 분리 보관된 개인정보는 5년간 보관 후 지체 없이
파기한다.<br /><br />

<strong id="scroll">제 24 조 (“포인트” 서비스)</strong><br /><br />
① “회사”는 서비스의 효율적 이용 및 운영을 위해 사전 공지 후 “포인트”의 일부 또는 
전부를 조정할 수 있으며, “포인트”는 “회사”가 정한 기간에 따라 주기적으로 소멸할 수 있습니다.<br />
②“포인트“를 현금으로 전환하기 위해서는 일자리매칭 서비스를 이용하여야 하며 적립 받은 “포인트”가 
3만점 이상 이어야 합니다.<br />
③“포인트”는 서비스 사용기간이 만료되거나 이용계약이 종료되면 소멸된다<br />
④“회사”는 “회원”이 부정한 목적이나 용도로 “포인트”를 사용 할 경우 “포인트”를 <br />
박탈하거나 “회원”자격을 정지할 수 있습니다.<br />
⑤“회원”개인신상에 변동(사망,국적변경등의 경우)이 생겨도 “포인트” 및 회원 혜택사항 등이 
가족이나 타인에게 상속 또는 양도 되지 않으며, 회원이 탈퇴하거나 사망한 경우 “포인트”는 자동 소멸 됩니다.<br /><br />

<strong>제 25 조 (위치정보 제공 동의)</strong><br /><br />
① “회원” 업무를 수행하는 동안에 “회원”의 위치정보가 “회사”에 제공되는
것에 동의합니다.<br />
② “회원”은 자신의 위치정보가 기업회원에게 실시간으로 표시되는 것에
동의한다.<br />
③ “회사”은 안전, 보안 목적 및 업무 서비스를 제공하기 위한
목적으로“회원”의 위치정보를 수집, 사용하고, 제3자와 공유할 수
있습니다.<br /><br />

<strong>제 26 조 (손해배상)</strong><br /><br />
① "회사"가 본 약관의 규정을 위반한 행위로 "회원"에게 손해를 입히거나
기타 "회사"가 제공하는 모든 서비스와 관련하여 "회사"의 책임 있는 사유로
인해 이용자에게 손해가 발생한 경우 "회사"는 그 손해를 배상하여야
한다.<br />
② "회사"는 책임 있는 사유로 제공한 정보가 사실과 달라 "회원"이 손해를
입었을 경우에 "회사"는 그 손해를 배상하여야 한다.<br />
③ "회원"이 본 약관의 규정을 위반한 행위로 "회사" 및 제3자에게 손해를
입히거나 "회원"의 책임 있는 사유에 의해 "회사" 및 제3자에게 손해를 입힌
경우에는 "회원"은 그 손해를 배상하여야 한다.<br />
④ 타 회원(개인회원, 기업회원, 기타회원 모두 포함)의 귀책사유로 "회원"의
손해가 발생한 경우 "회사"는 이에 대한 배상 책임이 없다.<br /><br />

<strong>제 27 조 (양도 금지)</strong><br /><br />
“회원”의 서비스 받을 권리는 제3자에게 양도, 대여, 증여 등으로 사용할 수
없다.<br /><br />

<strong>제 28 조 (보안 및 비밀유지등)</strong><br /><br />
① “회사”는 본 약관을 통하여 습득한 “회원”에 관한 정보나 자료를 “회원”의
사전 동의 없이 제3자에게 제공, 공표 또는 누설하여서는 안 된다..<br />
② “회원”은 본 약관을 통하여 습득한 “회사” 또는 회사의 고객에 관한 정보나
자료를 회사의 사전 동의 없이 녹취(녹음), 사진촬영, 복제, 저장하거나,
제3자에게 제공, 공표 또는 누설하여서는 안 된다.<br />
③ “회원”이 본 약관에 따른 업무 목적을 위해서만 회사의 프로그램(앱)을
사용하여야 하며, 그 과정에서 회사 또는 회사의 고객 정보가 제3자에게
유출되지 않도록 보안 유지의무를 다하여야 합니다.<br />
④ “회원”은 “회사”의 상호, 상표 및 기타 특정될 수 있는 표지를 “회사”의
사전 동의 없이 본 약관의 이행을 위한 목적 외로 사용하여서는 안 된다.<br />
⑤ 본 약관이 종료하는 경우, “회원”은 회사로부터 제공받은 정보 및 자료를
회사의 요청에 따라 반환하고, 반환이 불가능한 경우 복원이 불가능한
방법으로 파기하여야 한다.<br />
⑥ 본 조에 따른 의무는 본 약관이 종료된 후에도 유효하게 적용됩니다.<br /><br />

<strong>제 29 조 ("회원"의 개인정보보호)</strong><br /><br />
"회사"는 "회원"의 개인정보보호를 위하여 노력해야 한다. "회원"의
개인정보보호에 관해서는 정보통신망이용촉진 및 정보보호 등에 관한 법률,
개인정보보호법에 따르고, "사이트"에 "개인정보처리방침"을 고지한다.<br /><br />

<strong>제 30 조 (신용정보의 제공 활용 동의)</strong><br /><br />
① "회사"가 회원가입과 관련하여 취득한 "회원"의 개인신용정보를 타인에게
제공하거나 활용하고자 할 때에는 신용정보의 이용 및 보호에 관한 법률
제23조의 규정에 따라 사전에 그 사유 및 해당기관 또는 업체명 등을 밝히고
해당 "회원"의 동의를 얻어야 한다.<br />
② 본 서비스와 관련하여 "회사"가 "회원"으로부터 신용정보의 이용 및 보호에
관한 법률에 따라 타인에게 제공 활용에 동의를 얻은 경우 "회원"은 "회사"가
신용정보 사업자 또는 신용정보 집중기관에 정보를 제공하여 "회원"의 신용을
판단하기 위한 자료로 활용하거나, 공공기관에서 정책 자료로 활용되도록
정보를 제공하는 데 동의한 것으로 간주한다.<br /><br />

<strong>제 31 조 (분쟁의 해결)</strong><br /><br />
① "회사"와 "회원"은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기
위하여 필요한 모든 노력을 하여야 한다.<br />
② 전항의 노력에도 불구하고, 동 분쟁에 관한 소송은 "회사"의 주소지
관할법원으로 한다.<br /><br />

부칙<br /><br />
- 본 약관은 2021년 10월 25일부터 시행한다.`

export const jejuEvent = `<p class="t1">
주식회사 탑스맨은 [제주도 한 달 살기 프로그램] 행사와 관련 참여자의 개인정보를
중요시하며 개인정보보호법 제 15조에 근거하여 다음과 같이 개인정보 수집 및 이용에
관한 동의를 받고자 합니다.</p><br/>

<p>개인정보 수집 및 이용의 목적</p>

<strong>참가자격 확인, 신청 확인, 결과 통보, 시상품(경품) 발송, 일정 안내 및 통계,
방역 관리, 초상권 사용 등원정대 진행 및 참가 신청 관리에 필요한 정보 수집 및
이용의 목적</strong> <br/> <br/>

<p>수집하려는 개인정보의 내용</p>

<strong>(필수) 성명, 생년월일, 연락처(휴대전화), 주소, 이메일, SNS주소</strong><br/>

<p>개인정보의 이용 및 보유기간</p>

<strong>개인정보 수집 및 이용 목적이 달성된 후에는 해당정보를 지체 없이 파기합니다.
단, 관계법령의 규정에 의하여 보전할 필요가 있는 경우 회사는 아래와 같이 관계법령에서
정한 일정한 기간동안 개인정보를 보관합니다.</strong> <br /> <br />

<strong>가. 계약 또는 청약철회 등에 관한 기록 : 5년(전자상거래등에서의 소비자보호에 관한 법률</strong><br/>
<strong>나. 대금결제 및 재화등의 공급에 관한 기록 : 5년(전자상거래등에서의 소비자 보호에 관한 법률)</strong><br />
<strong>다. 소비자의 불만 또는 분쟁처리에 관한 기록 :3년(전자상거래등에서의 소비자보호에 관한 법률)</strong><br /><br />

<p>동의거부 및 불이익 내용</p>

<strong>정보 주체는 개인정보의 수집 및 이용에 대한 동의를 거부할 수 잇으며 동의 거부 시 제주도 한달 살기 프로그램에 참가 및 신청할 수 없습니다.</strong>
<br /><br />
<p style="color:red;  font-weight:bold;">※ 본인은「개인정보보호법」등 관련 법규에 의거하여 위와 같은 개인정보 수집 및 이용에 관하여 동의합니다.</p>`

export const jejuSnsContents = `
모집대상 : 제주에서 한 달 생활하며 호텔 근무에 필요한 서비스와 실습을 수행하고 제주에서의 경험을 SNS를 통해 홍보해주실 대학생

모집기간 : 2022.05.18(수) - 2022.06.05(일)

행사기간 : 2022.06.26(일) - 2022.07.24(일) [총 28박 29일]

체험장소 : 베니키아 호텔 제주 / 제주 올레 리조트 / UTOP호텔 등

참가자 혜택 :
    1.왕복항공료
    2.숙식 제공
    3.체험 수당 제공
    4.특강 : 성균관대 김시래 교수(디지털 시대의 생존력)
            -현) 마케팅 자문(호구153, 롯데자이언츠)
            -전) 농심기획 대표
            -전) 제일기획 본부장

#탑스맨 #호구153 #호텔구인 #호텔구직 #제주도 #한달살기 #제주도한달살기 #꿀알바

호텔 구인/구직을 간편하게 이용할 수 있는, 고용시장의 패러다임을 바꿀 차세대 호텔 구인구직 플랫폼 호구153

https://www.hogoo153.com/jejuDetail

안드로이드'구글플레이에서 앱 다운받기

https://han.gl/SeQKO

ios:Apple '앱스토어'에서 앱 다운받기
    
https://c11.kr/zrj4
    `
